import React from "react";
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Portfolio = () => {
  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          arrow: false,
          autoplay: false,
          draggable: true,
          speed: 300,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="portfolio_inner my_carousel gallery_zoom">
      <ul data-aos="fade-right" data-aos-duration="1200">
        <Slider {...settings}>
          <li>
            <div className="list_inner">
              <div className="image">
                <a
                  href="https://the-cooking-ninja.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="details"
                >
                  <img
                    src="img/portfolio/cooking.png"
                    data-tip
                    data-for="cooking"
                    alt="portfolio"
                  />
                  <ReactTooltip
                    id="cooking"
                    place="bottom"
                    type="light"
                    effect="float"
                    className="tooltip-wrapper"
                  >
                    <div>
                      <h5>Cooking Ninja</h5>
                      <span>React.JS,</span>
                      <span>Firebase</span>
                    </div>
                  </ReactTooltip>
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <a
                  href="https://the-magic-match.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                  className="details"
                >
                  <img
                    src="img/portfolio/themagicmatch.png"
                    data-tip
                    data-for="magicmatch"
                    alt="portfolio"
                  />
                  <ReactTooltip
                    id="magicmatch"
                    place="bottom"
                    type="light"
                    effect="float"
                    className="tooltip-wrapper"
                  >
                    <div>
                      <h5>The Magic Match</h5>
                      <span>React.JS</span>
                    </div>
                  </ReactTooltip>
                </a>
              </div>
            </div>
          </li>
          {/* End li */}

          <li>
            <div className="list_inner">
              <div className="image">
                <a
                  href="https://myonline-todo.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="details"
                >
                  <img
                    src="img/portfolio/todo.png"
                    data-tip
                    data-for="todo"
                    alt="portfolio"
                  />
                  <ReactTooltip
                    id="todo"
                    place="bottom"
                    type="light"
                    effect="float"
                    className="tooltip-wrapper"
                  >
                    <div>
                      <h5>MyOnline-ToDo</h5>
                      <span>Node.JS,</span>
                      <span>Express.JS,</span>
                      <span>MongoDB,</span>
                      <span>Express-Session</span>
                    </div>
                  </ReactTooltip>
                </a>
              </div>
            </div>
          </li>
          {/* End li */}

          <li>
            <div className="list_inner">
              <div className="image">
                <a
                  href="https://simonmemorygame.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="details"
                >
                  <img
                    src="img/portfolio/simongame.png"
                    data-tip
                    data-for="simongame"
                    alt="portfolio"
                  />
                  <ReactTooltip
                    id="simongame"
                    place="bottom"
                    type="light"
                    effect="float"
                    className="tooltip-wrapper"
                  >
                    <div>
                      <h5>Simon Game</h5>
                      <span>HTML,</span>
                      <span>CSS,</span>
                      <span>jQuery</span>
                    </div>
                  </ReactTooltip>
                </a>
              </div>
            </div>
          </li>
          {/* End li */}

          <li>
            <div className="list_inner">
              <div className="image">
                <a
                  href="https://my-miniblog.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="details"
                >
                  <img
                    src="img/portfolio/miniblog.png"
                    data-tip
                    data-for="miniblog"
                    alt="portfolio"
                  />
                  <ReactTooltip
                    id="minigblog"
                    place="bottom"
                    type="light"
                    effect="float"
                    className="tooltip-wrapper"
                  >
                    <div>
                      <h5>My-MiniBlog</h5>
                      <span>Node.JS,</span>
                      <span>Express.JS,</span>
                      <span>MongoDB</span>
                    </div>
                  </ReactTooltip>
                </a>
              </div>
            </div>
          </li>
          {/* End li */}
          {/*
          <li>
            <div className="list_inner">
              <div className="image">
                <a
                  href="https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template"
                  target="_blank"
                  rel="noreferrer"
                  className="details"
                >
                  <img
                    src="img/portfolio/5.jpg"
                    data-tip
                    data-for="web"
                    alt="portfolio"
                  />
                  <ReactTooltip
                    id="web"
                    place="bottom"
                    type="light"
                    effect="float"
                    className="tooltip-wrapper"
                  >
                    <div>
                      <h5>Web Apps Shot</h5>
                      <span>Web Apps</span>
                    </div>
                  </ReactTooltip>
                </a>
              </div>
            </div>
          </li> */}
          {/* End li */}
        </Slider>
      </ul>
    </div>
  );
};

export default Portfolio;
