import React, { useEffect } from "react";

function Edabit() {

  useEffect(() => {
    window.location.href = "https://edabit.com/user/Z9jhP3WeN7jSf4aFs";
  }, []);

  return (
    <div>
    </div>
  );
}

export default Edabit;