import React from "react";
//import HomeLight from "../views/all-home-version/HomeLight";
import HomeDark from "../views/all-home-version/HomeDark";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import Edabit from "../views/all-home-version/Edabit";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
        <Route exact path="/" component={HomeDark} />
          {/*<Route path="/home-light" component={HomeLight} />*/}          
          <Route path="/home-dark" component={HomeDark} />
          <Route path="/edabit" component={Edabit} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
