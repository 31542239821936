import React from "react";
import {
  /*FiFacebook,
  FiTwitter,
  FiInstagram,
  FiDribbble,
  FiLinkedin,*/
  FiGithub  
} from "react-icons/fi";

const SocialShare = [
 
  {
    naem: "Stackoverflow",
    iconLink: "img/logo/stack.png",
    link: "https://stackoverflow.com/users/story/14600583"
  },
  {
    name: "Edabit",
    iconLink: "img/logo/edabit.png",
    link: "https://edabit.com/user/Z9jhP3WeN7jSf4aFs"
  },
  {
    iconName: <FiGithub />,
    link: "https://www.github.com/BBacim",
  }
];
/*style={{height: "20px"}}*/
const Social = () => {
  return (
    <ul>
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a style={{height: "30px"}} href={val.link} target="_blank" rel="noreferrer">
            {val.iconName || <img src={val.iconLink} alt={val.name}></img>} 
          </a>
        </li>
      ))}
    </ul>    
  );
};

export default Social;
